import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { CloneExportTemplatePayload, ExportTemplate } from 'state-domains/domain/subscription';

import { buildExportTemplateUrl } from '../../../network/urls';
import { convertToCamel, convertToSnake, sendRequestWithXSRFToken } from '../../../utils';

export const cloneExportTemplate = (
    payload: CloneExportTemplatePayload,
): Observable<ExportTemplate> => {
    const url = buildExportTemplateUrl();
    return sendRequestWithXSRFToken(url, convertToSnake(payload), 'POST').pipe(
        switchMap(({ response }: AjaxResponse<ExportTemplate>) => {
            const clonedTemplate = convertToCamel<ExportTemplate>(response);
            return observableOf(clonedTemplate);
        }),
    );
};
